/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import { isBrowser } from '../shared/Helpers';

// UI lib components

// UI local components
import Seo from '../shared/Seo';
import PageLayout from '../shared/PageLayout';

// styles
import '../page-styles/index.scss';

const Hero = React.lazy(() => import('../page-components/home/Hero'));
const AboutUs = React.lazy(() => import('../page-components/home/AboutUs'));
const Offers = React.lazy(() => import('../page-components/home/Offers'));
const Values = React.lazy(() => import('../page-components/home/Values'));
// const Tls = React.lazy(() => import('../page-components/home/Testimonials'));
const Ops = React.lazy(() => import('../page-components/home/Opportunities'));
const Contact = React.lazy(() => import('../page-components/home/Contact'));

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function IndexPage() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo
        title={t(
          'MS² Consulting - digitalisation et sourcing des compétences rares',
        )}
      />
      {isBrowser && (
        <React.Suspense fallback={<div />}>
          <Hero />
          <AboutUs />
          <Offers />
          <Values />
          {/* <Tls /> */}
          <Ops />
          <Contact />
        </React.Suspense>
      )}
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
